<template>
  <div id="certificate">
    <el-dialog
      :title="certificateFormTitle"
      width="680px"
      :visible.sync="certificateDialogVisible"
      :close-on-click-modal="false"
      @close="certificateDialogClose"
    >
      <el-form
        ref="certificateFormRef"
        :model="certificateForm"
        :rules="certificateFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="证书编号" prop="certificateNo">
              <el-input v-model="certificateForm.certificateNo" placeholder="请输入证书编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="validPeriod">
              <el-date-picker v-model="certificateForm.validPeriod" placeholder="请选择有效期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="费用" prop="cost">
              <el-input v-model="certificateForm.cost" placeholder="请输入费用" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编辑人" prop="editor">
              <el-select v-model="certificateForm.editor" placeholder="请选择编辑人" clearable>
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.realName"
                  :value="item.realName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="提醒时间(天)" prop="reminderTime">
              <el-input v-model="certificateForm.reminderTime" placeholder="请输入提醒时间(天)" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="附件">
              <el-scrollbar style="height: 95.2px">
                <el-upload
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                >
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="certificateDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="certificateFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="证书编号">
        <el-input v-model="searchForm.certificateNo" placeholder="请输入证书编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="certificatePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="certificateNo" label="证书编号" />
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <span v-if="scope.row.validPeriod">{{ scope.row.validPeriod.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cost" label="费用" />
      <el-table-column prop="editor" label="编辑人" />
      <el-table-column prop="reminderTime" label="提醒时间(天)" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="certificatePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addCertificate,
  deleteCertificate,
  updateCertificate,
  selectCertificateInfo,
  selectCertificateList
} from '@/api/sale/certificate'
import { selectUserList } from '@/api/system/user'
import { upload } from '@/api/main/file'

export default {
  data () {
    return {
      certificateDialogVisible: false,
      certificateFormTitle: '',
      certificateForm: {
        id: '',
        certificateNo: '',
        validPeriod: '',
        cost: '',
        editor: '',
        reminderTime: '',
        fileName: '',
        fileUrl: ''
      },
      certificateFormRules: {
        certificateNo: [{ required: true, message: '证书编号不能为空', trigger: ['blur', 'change']}],
        validPeriod: [{ required: true, message: '有效期不能为空', trigger: ['blur', 'change']}]
      },
      certificatePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        certificateNo: ''
      },
      userList: [],
      fileList: []
    }
  },
  created () {
    selectCertificateList(this.searchForm).then(res => {
      this.certificatePage = res
    })
    selectUserList({ deptId: window.g.SALE_DEPT_ID }).then(res => {
      this.userList = res.list
    })
  },
  methods: {
    certificateDialogClose () {
      this.$refs.certificateFormRef.resetFields()
      this.$refs.uploadRef.clearFiles()
      this.fileList = []
    },
    certificateFormSubmit () {
      if (this.certificateFormTitle === '医疗器械证书详情') {
        this.certificateDialogVisible = false
        return
      }
      this.$refs.certificateFormRef.validate(async valid => {
        if (valid) {
          this.certificateForm.fileUrl = this.fileList.map(file => file.path).join(',')
          this.certificateForm.fileName = this.fileList.map(file => file.name).join(',')
          if (this.certificateFormTitle === '新增医疗器械证书') {
            await addCertificate(this.certificateForm)
          } else if (this.certificateFormTitle === '修改医疗器械证书') {
            await updateCertificate(this.certificateForm)
          }
          this.certificatePage = await selectCertificateList(this.searchForm)
          this.certificateDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.certificateFormTitle = '新增医疗器械证书'
      this.certificateDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCertificate(row.id)
        if (this.certificatePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.certificatePage = await selectCertificateList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.certificateFormTitle = '修改医疗器械证书'
      this.certificateDialogVisible = true
      this.selectCertificateInfoById(row.id)
    },
    handleInfo (index, row) {
      this.certificateFormTitle = '医疗器械证书详情'
      this.certificateDialogVisible = true
      this.selectCertificateInfoById(row.id)
    },
    selectCertificateInfoById (id) {
      selectCertificateInfo(id).then(res => {
        this.certificateForm.id = res.id
        this.certificateForm.certificateNo = res.certificateNo
        this.certificateForm.validPeriod = res.validPeriod
        this.certificateForm.cost = res.cost
        this.certificateForm.editor = res.editor
        this.certificateForm.reminderTime = res.reminderTime
        if (res.fileUrl) {
          const fileUrl = res.fileUrl.split(',')
          const fileName = res.fileName.split(',')
          this.fileList = []
          for (let i = 0; i < fileUrl.length; i++) {
            this.fileList.push({
              name: fileName[i],
              path: fileUrl[i]
            })
          }
        }
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCertificateList(this.searchForm).then(res => {
        this.certificatePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCertificateList(this.searchForm).then(res => {
        this.certificatePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCertificateList(this.searchForm).then(res => {
        this.certificatePage = res
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style>
#certificate .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
