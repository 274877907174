import axios from '@/common/axios'
import qs from 'qs'

export function addCertificate (data) {
  return axios({
    method: 'post',
    url: '/sale/certificate/add',
    data: qs.stringify(data)
  })
}

export function deleteCertificate (id) {
  return axios({
    method: 'delete',
    url: '/sale/certificate/delete/' + id
  })
}

export function updateCertificate (data) {
  return axios({
    method: 'put',
    url: '/sale/certificate/update',
    data: qs.stringify(data)
  })
}

export function selectCertificateInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/certificate/info/' + id
  })
}

export function selectCertificateList (query) {
  return axios({
    method: 'get',
    url: '/sale/certificate/list',
    params: query
  })
}

export function remindCertificateList (query) {
  return axios({
    method: 'get',
    url: '/sale/certificate/remind',
    params: query
  })
}
